import {
  Flex,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button, 
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "@chakra-ui/react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa'; // FontAwesome copy icon
import React, { useMemo, useState } from "react";
import { SearchBar } from "../../../../components/navbar/searchBar/SearchBar";
import { MdOutlineCalendarToday } from "react-icons/md";
import { Link } from 'react-router-dom';
import ontransaction from "../../../../assets/img/notransactions.jpg"

// Custom components
import Card from "components/card/Card";

// Assets
export default function ColumnsTable(props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {tableData, filterfun, refresh}=props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [copiedRecipientWalletAddress, setCopiedRecipientWalletAddress] = useState(false);
  const [copiedRefundWalletAddress, setCopiedRefundWalletAddress] = useState(false);
  const [copiedExtraID, setCopiedExtraID] = useState(false);
  const [copiedRefundExtraID, setCopiedRefundExtraID] = useState(false);
  const [copiedTransactionID, setCopiedTransactionID] = useState(false);
  const [copiedEmail, setCopiedEmail] = useState(false);
  const [txHash, setCopiedTxHash] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [status, setStatus] = useState("All");
  const [period, setPeriod] = useState("This Day");

  const handleViewClick = (index) => {
    setSelectedTransaction(index);
    onOpen();
  };

  function formatCustomDate(isoDateStr) {
    const date = new Date(isoDateStr);
  
    // Get year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
  
    // Get hours, minutes, and seconds
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    // Determine AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    
    // Convert 24-hour time to 12-hour time
    hours = hours % 12 || 12; // If hours is 0, make it 12 (for midnight)
  
    // Format final string as YYYY-MM-DD H:MM:SS AM/PM
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
  
    return formattedDate;
  }

  const handleModalClose = () => {
    setSelectedTransaction(null);
    onClose();
  };
  
  return (
    <Card
      direction='column'
      h="750px"
      w='100%'
      px='0px'
      mt="15px"
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          All Swaps Till Present Date
        </Text>
        {/* <Menu /> */}
        <div style={{display:"flex"}}>
        <SearchBar mb={true ? { base: '10px', md: 'unset' } : 'unset'} me="10px" borderRadius="30px" filterfun={filterfun} />
        <div className="dropdown">
        <Button
            className="btn dropdown-toggle t-table-btn"
            id="dropdownMenuButton"
            data-toggle="dropdown" 
            aria-haspopup="true" 
            aria-expanded="false"
            bg={boxBg}
            style={{marginRight:"12px"}}
            fontSize='sm'
            fontWeight='500'
            color={textColorSecondary}
            borderRadius='7px'>
            <Icon
              as={MdOutlineCalendarToday}
              color={textColorSecondary}
              me='4px'
            />
            {period}
          </Button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" onClick={()=>{setPeriod("This Hour"); props.apiCallForStatusAndPeriod(status, "This Hour")}}>This Hour</a>
              <a class="dropdown-item" onClick={()=>{setPeriod("This Day"); props.apiCallForStatusAndPeriod(status, "This Day")} }>This Day</a>
              <a class="dropdown-item" onClick={()=>{setPeriod("This Week"); props.apiCallForStatusAndPeriod(status, "This Week")}}>This Week</a>
              <a class="dropdown-item" onClick={()=>{setPeriod("This Month"); props.apiCallForStatusAndPeriod(status, "This Month")}}>This Month</a>
              <a class="dropdown-item" onClick={()=>{setPeriod("This Year"); props.apiCallForStatusAndPeriod(status, "This Year")}}>This Year</a>
         </div>
        </div>
        <div className="dropdown">
        <Button
            className="btn dropdown-toggle t-table-btn"
            id="dropdownMenuButton"
            data-toggle="dropdown" 
            aria-haspopup="true" 
            aria-expanded="false"
            bg={boxBg}
            style={{marginRight:"12px"}}
            fontSize='sm'
            fontWeight='500'
            color={textColorSecondary}
            borderRadius='7px'>
            <Icon
              as={MdOutlineCalendarToday}
              color={textColorSecondary}
              me='4px'
            />
            {status}
          </Button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" onClick={()=>{setStatus("All"); props.apiCallForStatusAndPeriod("All", period)}}>All</a>
              <a class="dropdown-item" onClick={()=>{setStatus("Successfull"); props.apiCallForStatusAndPeriod("Successfull", period)}}>Successfull</a>
              <a class="dropdown-item" onClick={()=>{setStatus("Pending"); props.apiCallForStatusAndPeriod("Pending", period)}}>Pending</a>
         </div>
        </div>
        <div className="dropdown">
        <Button
            className="btn dropdown-toggle t-table-btn"
            id="dropdownMenuButton"
            data-toggle="dropdown" 
            aria-haspopup="true" 
            aria-expanded="false"
            bg={boxBg}
            style={{marginRight:"12px"}}
            fontSize='sm'
            fontWeight='500'
            color={textColorSecondary}
            borderRadius='7px'>
            <Icon
              as={MdOutlineCalendarToday}
              color={textColorSecondary}
              me='4px'
            />
            Support Links
          </Button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href={props.exchangelinks.telegram} target="_blank">Telegram</a>
              <a class="dropdown-item" href={props.exchangelinks.gmail} target="_blank">Gmail</a>
              <a class="dropdown-item" href={props.exchangelinks.slack} target="_blank">Slack</a>
         </div>
        </div>
        <Button className="refresh" onClick={refresh}>Refresh</Button>
        </div>
      </Flex>
      <Table  variant='simple' color='gray.500' mb='24px'>
        <Thead>
            <Tr>
              
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Text textAlign={"center"}>
                  STATUS
                  </Text>
                </Th>

                <Th
                  pe='10px'
                  borderColor={borderColor}>
                    TRANSACTION TYPE
                </Th>

                <Th
                  pe='10px'
                  borderColor={borderColor}>
                    SELL AMOUNT
                </Th>

                <Th
                  pe='10px'
                  borderColor={borderColor}>
                    GET AMOUNT
                </Th>


                <Th
                  pe='10px'
                  borderColor={borderColor}>
                    DATE
                </Th>

                <Th
                  pe='10px'
                  borderColor={borderColor}>
                    PROFIT
                </Th>

                <Th
                  pe='10px'
                  borderColor={borderColor}>
                    ACTIONS
                </Th>
            </Tr>
        </Thead>
        <Tbody>
          {
            tableData.map((tx, index)=>{

// Format date and time
let creationDateTime = formatCustomDate(tx.time);
let confirmationDateTime = null;
confirmationDateTime=tx.start_time && formatCustomDate(tx.start_time);
let completionDateTime = null;
completionDateTime = tx.completion_time && formatCustomDate(tx.completion_time);

              return(
                <Tr key={index}>
                {/* justifyContent="center" */}
                      <Td
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                          <Flex align='center' justifyContent="center" padding={"2px 0px"} background={tx.status=="success" || tx.status=="finished"?"#d7e5c7":"#d4d4f0"} borderRadius={"5px"} >
                          <Text color={tx.status=="success"|| tx.status=="finished"?"#43883a":"#5151c3"} fontSize='sm' fontWeight='700'>
                            {tx.status.toUpperCase()}
                          </Text>
                        </Flex>
                      </Td>
                      <Td
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                          <Flex align='center'>
                          <Text color={textColor} fontSize='sm' fontWeight='400'>
                            {tx.transaction_type.toUpperCase()}
                          </Text>
                        </Flex>
                      </Td>
                      <Td
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                          <Flex align='center'>
                          <Text color={textColor} fontSize='sm' fontWeight='400' marginRight={"5px"}>
                          {parseFloat(tx.sell_amount).toFixed(5)}
                          </Text>
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {tx.sell_coin.toUpperCase()}
                          </Text>
                        </Flex>
                      </Td>
                      <Td
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                          <Flex align='center'>
                          <Text color={textColor} fontSize='sm' fontWeight='400' marginRight={"5px"}>
                          {parseFloat(tx.get_amount).toFixed(5)}
                          </Text>
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {tx.get_coin.toUpperCase()}
                          </Text>
                        </Flex>
                      </Td>
                      <Td
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {creationDateTime}
                          </Text>
                      </Td>
                      <Td
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                          <Flex align='center'>
                          <Text color={textColor} fontSize='sm' fontWeight='400'>
                            {tx.average_profit_percent}<span style={{fontWeight:"700"}}> BTC</span>
                          </Text>
                        </Flex>
                      </Td>
                      <Td
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        <Flex align='center'>
                          <Button onClick={()=>{handleViewClick(index)}} colorScheme='blue' variant='solid' key={index}>View</Button>
                          {selectedTransaction==index && <Modal isOpen={isOpen} onClose={()=>{handleModalClose(tx)}} key={index}>
        <ModalOverlay           sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(2px)'
          }} />
        <ModalContent   sx={{
    width: '70vw',
    height: 'auto',
    maxWidth: 'none', // Override default maxWidth
  }}>
          <ModalHeader><Flex justifyContent={"center"} fontSize={"30px"}>SWAP DETAILS</Flex></ModalHeader>
          
          <ModalCloseButton />

          <ModalBody>
            <div style={{marginTop:"2%"}}>
            <div className="transaction-detail-divs"><span>Exchange Creation Time: </span><span>{creationDateTime} </span></div>
            <div className="transaction-detail-divs" style={{display:confirmationDateTime?"block":"none"}}><span>Exchange Confirmation Time: </span><span>{confirmationDateTime} </span></div>
            <div className="transaction-detail-divs" style={{display:completionDateTime?"block":"none"}}><span>Exchange Completion Time: </span><span>{completionDateTime} </span></div>
            <div className="transaction-detail-divs"><span >Status: </span><span style={{color:tx.status=="success"|| tx.status=="finished"?"#43883a":"#5151c3", background:tx.status=="success" || tx.status=="finished"?"#d7e5c7":"#d4d4f0", borderRadius:"5px", padding:"4px 8px"}}>{tx.status.toUpperCase()} </span></div>
            <div className="transaction-detail-divs"><span>Sell Amount: </span><span>{tx.sell_amount+ " "+ tx.sell_coin.toUpperCase()} </span></div>
            <div className="transaction-detail-divs"><span>Get Amount: </span><span>{tx.get_amount+ " "+ tx.get_coin.toUpperCase()} </span></div>
            <div className="transaction-detail-divs"><span>Transaction Type: </span><span>{tx.transaction_type.toUpperCase()} </span></div>
            <div className="transaction-detail-divs"><span>Recipient Wallet Address: </span><span>{tx.recipient_address} </span>
            <div className="copy-to-clipboard">
              <CopyToClipboard text={tx.recipient_address} onCopy={()=>{
                      setCopiedRecipientWalletAddress(true);
                      setTimeout(() => {
                        setCopiedRecipientWalletAddress(false);
                      }, 2000); 
              }}>
                <button className="copy-button">
                <FaCopy />
               </button>
              </CopyToClipboard>
              {copiedRecipientWalletAddress && <span className="copy-feedback" >Copied!</span>}
            </div>
            </div>
            <div className="transaction-detail-divs" style={{display:tx.recipient_extraid?"flex":"none"}}><span>Recipient Extra ID: </span><span>{tx.recipient_extraid} </span>
            <div className="copy-to-clipboard">
              <CopyToClipboard text={tx.recipient_extraid} onCopy={()=>{
                setCopiedExtraID(true);
                setTimeout(() => {
                  setCopiedExtraID(false);
                }, 2000); // Reset copied state after 2 seconds
              }}>
                <button className="copy-button">
                <FaCopy />
               </button>
              </CopyToClipboard>
              {copiedExtraID && <span className="copy-feedback">Copied!</span>}
            </div>
            </div>
            <div className="transaction-detail-divs"><span>Refund Wallet Address: </span><span>{tx.refund_address} </span>
            <div className="copy-to-clipboard">
              <CopyToClipboard text={tx.refund_address} onCopy={()=>{
                      setCopiedRefundWalletAddress(true);
                      setTimeout(() => {
                        setCopiedRefundWalletAddress(false);
                      }, 2000); // Reset copied state after 2 seconds
              }}>
                <button className="copy-button">
                <FaCopy />
               </button>
              </CopyToClipboard>
              {copiedRefundWalletAddress && <span className="copy-feedback">Copied!</span>}
            </div>
            </div>
            <div className="transaction-detail-divs" style={{display:tx.refund_extraid?"flex":"none"}}><span>Refund Extra ID: </span><span>{tx.refund_extraid} </span>
            <div className="copy-to-clipboard">
              <CopyToClipboard text={tx.refund_extraid} onCopy={()=>{
                      setCopiedRefundExtraID(true);
                      setTimeout(() => {
                        setCopiedRefundExtraID(false);
                      }, 2000); // Reset copied state after 2 seconds
              }}>
                <button className="copy-button">
                <FaCopy />
               </button>
              </CopyToClipboard>
              {copiedRefundExtraID && <span className="copy-feedback">Copied!</span>}
            </div>
            </div>
            <div className="transaction-detail-divs"><span>Transaction ID: </span><span>{tx.transaction_id} </span>
            <div className="copy-to-clipboard">
              <CopyToClipboard text={tx.transaction_id} onCopy={()=>{
                      setCopiedTransactionID(true);
                      setTimeout(() => {
                        setCopiedTransactionID(false);
                      }, 2000); // Reset copied state after 2 seconds
              }}>
                <button className="copy-button">
                <FaCopy />
               </button>
              </CopyToClipboard>
              {copiedTransactionID && <span className="copy-feedback">Copied!</span>}
            </div>
            </div>
            <div className="transaction-detail-divs" style={{display:tx.tx_hash!=null?"flex":"none"}}><span>Transaction Hash: </span><span>{tx.tx_hash} </span>
            <div className="copy-to-clipboard">
              <CopyToClipboard text={tx.tx_hash} onCopy={()=>{
                      setCopiedTxHash(true);
                      setTimeout(() => {
                        setCopiedTxHash(false);
                      }, 2000); // Reset copied state after 2 seconds
              }}>
                <button className="copy-button">
                <FaCopy />
               </button>
              </CopyToClipboard>
              {txHash && <span className="copy-feedback">Copied!</span>}
            </div>
            </div>
            <div className="transaction-detail-divs" style={{display:tx.tx_hash_link!=null?"flex":"none"}}><span>Transaction Explorer: </span><span>{tx.tx_hash_link} </span>
            <div className="copy-to-clipboard">
              <CopyToClipboard text={tx.tx_hash_link} onCopy={()=>{
                      setCopiedTxHash(true);
                      setTimeout(() => {
                        setCopiedTxHash(false);
                      }, 2000); // Reset copied state after 2 seconds
              }}>
                <button className="copy-button">
                <FaCopy />
               </button>
              </CopyToClipboard>
              {txHash && <span className="copy-feedback">Copied!</span>}
            </div>
            </div>
            <div className="transaction-detail-divs" style={{display:tx.email?"flex":"none"}}><span>Recipient Email: </span><span>{tx.email} </span>
            <div className="copy-to-clipboard">
              <CopyToClipboard text={tx.email} onCopy={()=>{
                      setCopiedEmail(true);
                      setTimeout(() => {
                        setCopiedEmail(false);
                      }, 2000); // Reset copied state after 2 seconds
              }}>
                <button className="copy-button">
                <FaCopy />
               </button>
              </CopyToClipboard>
              {copiedEmail && <span className="copy-feedback">Copied!</span>}
            </div>
            </div>
            </div>
          </ModalBody>

          <ModalFooter>
          </ModalFooter>
        </ModalContent>
                          </Modal>}
                        </Flex>
                      </Td>
                </Tr>
              )
            })
          }

        </Tbody>
      </Table>
      <div style={{display:props.transactionl>0?"none":"flex", height:"100%", alignItems:"center", justifyContent:"center"}}>
      <img src={ontransaction} style={{width:"300px"}}></img>
      </div>
    </Card>
  );
}
