/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, SimpleGrid, Flex } from "@chakra-ui/react";
import ComplexTable from "views/admin/dataTables/components/ComplexTable";

import React, { useEffect, useState } from "react";

export default function Settings() {

  const [tabledata, setTableData]=useState([]);
  const [filteredTransactions, setFilteredTransactions]=useState([]);
  const [presentTransactions, setPresentTransactions]=useState([]);
  const [currentPage, setCurrentPage]=useState(1);
  const [transactionsPerPage, setTransactionsPerPage]=useState(8);
  const [exchangelinks, setExchangeLinks]=useState({});
  const [status, setStatus] = useState("");
  const [period, setPeriod] = useState("current_day");
  const [transactionl, setTransactionL] =useState(0);
  const [refreshstatus, setRefreshStatus] =useState();
  const [refreshperiod, setRefreshPeriod] =useState();
  
  async function apiCall(stat, perd){
    setRefreshStatus(stat);
    setRefreshPeriod(perd);
    const options1={
      method:"GET",
      headers:{
        "Content-Type": "application/json" 
      }
    }

      const options2={
        method:"POST",
        headers:{
          "Content-Type": "application/json"
        },
        body:JSON.stringify({period:perd, status:stat})
      }
      const response2=await fetch (process.env.REACT_APP_URL+"/get/changenow/transactions", options2);
      const data2=await response2.json();
      setTransactionL(data2.length);

      //Support Links Response
      const response1=await fetch (process.env.REACT_APP_URL+"/support_links/changenow", options1);
      const data1=await response1.json();
      const linksobj={
        exchange_name:data1.exchange_name,
        telegram:data1.telegram,
        slack:data1.slack,
        gmail:data1.gmail
      }
      setExchangeLinks(linksobj);
      setPresentTransactions(data2);
      setTableData(data2);
    }
    function refresh(){
      apiCall(refreshstatus, refreshperiod)
    }

  // Chakra Color Mode
  useEffect(()=>{
    apiCall(status, period)
    }, [])

    const indexOfLastTransaction = currentPage * transactionsPerPage;
    const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
    const currentTransactions =  tabledata.slice(indexOfFirstTransaction, indexOfLastTransaction);

    const pageNumbers=[];
    for (let i = 1; i<= Math.ceil( tabledata.length / transactionsPerPage); i++ ){
      pageNumbers.push(i);
    }

    function paginate(e, number){
      e.preventDefault();
      setCurrentPage(number);
    }

    function nextPage(){
      if(currentPage<pageNumbers.length){
        setCurrentPage(currentPage+1);
      }
    }

    function prevPage(){
      if(currentPage>1){
        setCurrentPage(currentPage-1);
      }
    }

    const filterTransactions = (filterValue) => {

      const searchTerm = filterValue.toLowerCase();
  
      const filteredTransactions = presentTransactions.filter((transaction) => {
        const { tx_hash, transaction_id, email } = transaction;
        return (
          (tx_hash && tx_hash.toLowerCase().includes(searchTerm)) ||
          (transaction_id && transaction_id.toLowerCase().includes(searchTerm)) ||
          (email && email.toLowerCase().includes(searchTerm))
        );
      });
  
      const exactMatch = [];
      const partialMatch = [];
  
      filteredTransactions.forEach((transaction) => {
        const { tx_hash, transaction_id, email } = transaction;
        if (
          (tx_hash && tx_hash.toLowerCase() === searchTerm) ||
          (transaction_id && transaction_id.toLowerCase() === searchTerm) ||
          (email && email.toLowerCase() === searchTerm)
        ) {
          exactMatch.push(transaction);
        } else {
          partialMatch.push(transaction);
        }
      });
      
      const rearrangedTransactions = exactMatch.concat(partialMatch);

      setFilteredTransactions(rearrangedTransactions);
      setTableData(rearrangedTransactions)
    
    };

    const apiCallForStatusAndPeriod=(status, period)=>{
      let getstatus=null;
      let getperiod=null;

      switch (status) {
        case "All":
          getstatus="";
          break;
        case "Successfull":
            getstatus="finished";
            break;
        case "Pending":
            getstatus="pending";
            break;
      }

      switch (period) {
        case 'This Hour':
          getperiod = 'current_hour';
            break;
        case 'This Day':
          getperiod = 'current_day';
            break;
        case 'This Week':
          getperiod = 'current_week';
            break;
        case 'This Month':
          getperiod = 'current_month';
            break;
        case 'This Year':
          getperiod = 'current_year';
            break;
        case 'all':
        default:
          getperiod = null;
    }
    apiCall(getstatus, getperiod);
  };
    

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1}}
        spacing={{ base: "20px", xl: "20px" }}>
        <ComplexTable
          tableData={currentTransactions}
          filterfun={filterTransactions}
          exchangelinks={exchangelinks}
          apiCallForStatusAndPeriod={apiCallForStatusAndPeriod}
          transactionl={transactionl}
          refresh={refresh}

        />
        <Flex display={transactionl>0?"flex":"none"} justifyContent={"center"}>
        <button onClick={prevPage} type="button" class="btn btn-primary" style={{marginRight:"10px", fontWeight:"bold"}} >{"<"}</button>
        <nav className="pagination">
          {pageNumbers.map((number)=>
            {
              if(currentPage==number  || pageNumbers.length==number){
                return(
                  <li key={number} className="page-item">
                    <a onClick={(e)=>{paginate(e, number)}} href="!#" className="page-link" style={{background:currentPage==number?"#2295ee":"white", color:currentPage==number?"white":"blue", fontWeight:"bold"}}>{number}</a>      
                  </li>
                  )
              }
              }
          )}
        </nav>
        <button onClick={nextPage} type="button" class="btn btn-primary" style={{marginLeft:"10px", fontWeight:"bold"}}>{">"}</button>
        </Flex>
      </SimpleGrid>
    </Box>
  );
}
