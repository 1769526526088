import React, {useEffect} from "react";

// Chakra imports
import { Flex, useColorModeValue, Text } from "@chakra-ui/react";
import CoinoSwapLogo from "../../../assets/img/logo.png";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";
import { useHistory } from "react-router-dom";

export function SidebarBrand() {

  const history=useHistory();
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  useEffect(()=>{
    const checksession=async()=>{
      const url=process.env.REACT_APP_URL+"/check/session";
      const options={
	    method:"POST",
      credentials: "include",
	      headers:{
		      'Content-Type':'application/json'
	      },
        body:JSON.stringify({
          session_id:localStorage.getItem("local_session_token")
        })
      }

const response=await fetch(url, options);
const data=await response.json();
console.log(data)

if(!data.session){
  
	history.push("/sign-in")
}
    }
    checksession();
  },[])

  return (
    <Flex align='center' direction='column'>
      {/* <HorizonLogo h='26px' w='175px' my='32px' color={logoColor} /> */}
      {/* <img className="img-fluid" style={{width:"175px", marginBottom: "10px"}} src={CoinoSwapLogo}></img> */}
      <Text fontSize={"35px"} fontWeight={"bold"} marginBottom={"-8px"}>CoinoSwap</Text>
      <Text marginBottom={"15px"}>BY COINNOISSUERS</Text>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
