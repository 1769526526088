import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import Settings from "views/admin/profile/settings.js";
import ChangellyTransactions from "views/admin/dataTables/changellyTransactions.js";
import ChangenowTransactions from "views/admin/dataTables/changenowTransactions.js";
import ChangeheroTransactions from "views/admin/dataTables/changeheroTransactions.js";
import ExolixTransactions from "views/admin/dataTables/exolixTransactions.js";
import LetsexchangeTransactions from "views/admin/dataTables/letsexchangeTransactions.js";
import GodexTransactions from "views/admin/dataTables/godexTransactions.js";
import SimpleswapTransactions from "views/admin/dataTables/simpleswapTransactions.js";
import StealthexTransactions from "views/admin/dataTables/stealthexTransactions.js";
import coinsData from "views/admin/dataTables/coinsData";



import RTL from "views/admin/rtl";

// Auth Imports
import SignInCentered from "views/auth/signIn/index.js";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "Changelly Swaps",
    layout: "/admin",
    path: "/changelly-transactions",
    icon:<Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    component: ChangellyTransactions,
    secondary: true,
  },
  {
    name: "Changenow Swaps",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/changenow-transactions",
    component: ChangenowTransactions,
  },  
  {
    name: "Changehero Swaps",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/changehero-transactions",
    component: ChangeheroTransactions,
  },
  {
    name: "Exolix Swaps",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/exolix-transactions",
    component: ExolixTransactions,
  },  
  {
    name: "Godex Swaps",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/godex-transactions",
    component: GodexTransactions,
  },
  {
    name: "Letsexchange Swaps",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/letsexchange-transactions",
    component: LetsexchangeTransactions,
  },
  {
    name: "Simpleswap Swaps",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/simpleswap-transactions",
    component: SimpleswapTransactions,
  },
  {
    name: "StealthEX Swaps",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/stealthex-transactions",
    component: StealthexTransactions,
  },
  {
    name: "Coins and Tokens",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/coins-tokens",
    component: coinsData,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "/settings",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Settings,
  },
  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "/sign-in",
  //   icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
  //   component: SignInCentered,
  // },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: RTL,
  // },
];

export default routes;
