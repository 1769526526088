import React,{useState, useEffect} from "react";
import OtpInput from 'react-otp-input'; 
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import {toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import { border } from "@chakra-ui/system";


function Otp() {

  const history = useHistory();
  const [otp, setOtp] = useState("");

  const verifyOtp=async()=>{
    try {
      console.log("Run")
      const url=process.env.REACT_APP_URL+"/otp/verify";

      const options={
        method:"POST",
        credentials: "include",
        headers:{
          'Content-Type':'application/json'
        },
        body:JSON.stringify({
          otp:otp,
          email:localStorage.getItem("local_varification_email")
        })
      }

      const response= await fetch(url, options);
      const data= await response.json();
      if(data.otp){
        localStorage.setItem("local_email", data.email);
        localStorage.setItem("local_first_name", data.first_name);
        localStorage.setItem("local_last_name", data.last_name);
        history.push('/admin/dashboard');
      }else{
          toast.error(data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
          });
      }

    } catch (error) {
      console.log(error)
      toast.error("Error connecting to the server", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
        });
    }

    

  }

  return (
    <div className="otp-background">
      <div className="otp-box">
      <div><img src="https://img.icons8.com/?size=100&id=108806&format=png&color=000000"></img></div>
      <p>Please check your email</p>
      <p>We`have sent a code to {localStorage.getItem("local_varification_email")}</p>
      <div>
      <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={6}
      renderSeparator={<span> - </span>}
      inputStyle={"otp-inputs"}
      renderInput={(props) => <input {...props} />}
    /></div>
    <div><button type="button" class="btn btn-primary" onClick={verifyOtp}>Verify</button></div>


      </div>
    </div>

  );
}

export default Otp;
