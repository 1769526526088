export const setItem = () => {

if(!localStorage.getItem("local_email")){
   localStorage.setItem("local_email", "")
}

if(!localStorage.getItem("local_varification_email")){
   localStorage.setItem("local_varification_email", "")
}

if(!localStorage.getItem("local_password")){
   localStorage.setItem("local_password", "")
}

if(!localStorage.getItem("local_first_name")){
    localStorage.setItem("local_first_name", "")
 }
 if(!localStorage.getItem("local_last_name")){
   localStorage.setItem("local_last_name", "")
}
 if(!localStorage.getItem("local_session_token")){
    localStorage.setItem("local_session_token", "")
 }
 if(!localStorage.getItem("local_remember_me")){
   localStorage.setItem("local_remember_me", false);
}

}