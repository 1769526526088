/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React,{useState, useEffect} from "react";
import changelly from "../../../assets/img/changelly_offer.png";
import changenow from "../../../assets/img/ChangeNow Offer.png";
import changehero from "../../../assets/img/Change Hero Offer.png";
import exolix from "../../../assets/img/Exolix Offer.png";
import godex from "../../../assets/img/Godex Offers.png";
import stealthex from "../../../assets/img/Stealthex Offer.png";
import letsexchange from "../../../assets/img/LetsExchange Offer.png";
import simpleswap from "../../../assets/img/SimpleSwap Offer.png";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import {
  columnsDataCheck,
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [changellycount, setChangellyCount]=useState(0);
  const [changenowcount, setChangenowCount]=useState(0);
  const [changeherocount, setChangeheroCount]=useState(0);
  const [exolixcount, setExolixCount]=useState(0);
  const [godexcount, setGodexCount]=useState(0);
  const [letsexchangecount, setLetsexchangeCount]=useState(0);
  const [stealthexcount, setStealthexCount]=useState(0);
  const [simpleswapcount, setSimpleswapCount]=useState(0);

  async function apiCall(stat, perd, path){

      const options={
        method:"POST",
        headers:{
          "Content-Type": "application/json"
        },
        body:JSON.stringify({period:perd, status:stat})
      }

      const response=await fetch (process.env.REACT_APP_URL+path, options);
      const data=await response.json();
      console.log(data);
      return data.length;
    }
  
    const periodfun=async (exchange, period)=>{
      switch(exchange){
        case "changelly":
         setChangellyCount(await apiCall("finished", period, "/get/changelly/transactions"));
        break;
        case "changenow":
          setChangenowCount(await apiCall("finished", period, "/get/changenow/transactions"));
        break;
        case "changehero":
          setChangeheroCount(await apiCall("finished", period, "/get/changehero/transactions"));
        break;
        case "exolix":
          setExolixCount(await apiCall("success", period, "/get/exolix/transactions"));
        break;
        case "godex":
          setGodexCount(await apiCall("success", period, "/get/godex/transactions"));
        break;
        case "letsexchange":
          setLetsexchangeCount(await apiCall("success", period, "/get/letsexchange/transactions"));
        break;
        case "stealthex":
          setStealthexCount(await apiCall("finished", period, "/get/stealthex/transactions"));
        break;
        case "simpleswap":
          setSimpleswapCount(await apiCall("finished", period, "/get/simpleswap/transactions"));
        break;
      }
    }

    useEffect(()=>{
      periodfun("changelly","finished");
      periodfun("changenow","finished");
      periodfun("exolix","success");
      periodfun("godex","finished");
      periodfun("letsexchange","success");
      periodfun("changehero","finished");
      periodfun("stealthex","finished");
      periodfun("simpleswap","finished");
    },[])


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <div className="container-fluid">
        <div className="row exchanges-count-div">
        <MiniStatistics
          startContent={
            <div style={{width:"160px", margin:"auto 0px"}}><img className="img-fluid" src={changelly}></img></div>
          }
          name='Successfull Swaps'
          value={changellycount}
          exchange="changelly"
          periodfun={periodfun}
          
        />
        <MiniStatistics
          startContent={
            <div style={{width:"90px", margin:"auto 0px"}}><img className="img-fluid" src={changenow}></img></div>
          }
          name='Successfull Swaps'
          value={changenowcount}
          exchange="changenow"
          periodfun={periodfun}
        />
        <MiniStatistics
          startContent={
            <div style={{width:"160px", margin:"auto 0px"}}><img className="img-fluid" src={changehero}></img></div>
          }
          name='Successfull Swaps'
          value={changeherocount}
          exchange="changehero"
          periodfun={periodfun}
        />
        <MiniStatistics
          startContent={
            <div style={{width:"160px", margin:"auto 0px"}}><img className="img-fluid" src={exolix}></img></div>
          }
          name='Successfull Swaps'
          value={exolixcount}
          exchange="exolix"
          periodfun={periodfun}
        />
        <MiniStatistics
          startContent={
            <div style={{width:"160px", margin:"auto 0px"}}><img className="img-fluid" src={godex}></img></div>
          }
          name='Successfull Swaps'
          value={godexcount}
          exchange="godex"
          periodfun={periodfun}
        />
        <MiniStatistics
          startContent={
            <div style={{width:"160px", margin:"auto 0px"}}><img className="img-fluid" src={letsexchange}></img></div>
          }
          name='Successfull Swaps'
          value={letsexchangecount}
          exchange="letsexchange"
          periodfun={periodfun}
        />
        <MiniStatistics
          startContent={
            <div style={{width:"160px", margin:"auto 0px"}}><img className="img-fluid" src={stealthex}></img></div>
          }
          name='Successfull Swaps'
          value={stealthexcount}
          exchange="stealthex"
          periodfun={periodfun}
        />
        <MiniStatistics
          startContent={
            <div style={{width:"160px", margin:"auto 0px"}}><img className="img-fluid" src={simpleswap}></img></div>
          }
          name='Successfull Swaps'
          value={simpleswapcount}
          exchange="simpleswap"
          periodfun={periodfun}
        />
        </div>
      </div>

      {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <TotalSpent />
        <WeeklyRevenue />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <Tasks />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );
}
