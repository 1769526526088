import React,{useState, useEffect} from "react";
import { NavLink } from "react-router-dom";
import $ from 'jquery';  // Import jQuery
import OtpInput from 'react-otp-input';


// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useToast } from '@chakra-ui/react'
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import {toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';


function SignIn() {

  const history = useHistory();
  const [email, setEmail]=useState("");
  const [password, setPassword]=useState("");
  console.log(process.env.PUBLIC_URL,"THIS.......................")

  const [rememberMe, setRememberMe]=useState(false);

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );


  const login=async()=>{
    try {
      console.log("Run")
      const url=process.env.REACT_APP_URL+"/login";

      const options={
        method:"POST",
        credentials: "include",
        headers:{
          'Content-Type':'application/json'
        },
        body:JSON.stringify({
          email:email,
          password:password
        })
      }

      const response= await fetch(url, options);
      const data= await response.json();
      // if(data.authorization){
      //   localStorage.setItem("local_email", data.email);
      //   localStorage.setItem("local_first_name", data.first_name);
      //   localStorage.setItem("local_last_name", data.last_name);
      //   localStorage.setItem("local_session_token", data.session_id);
      //   if(rememberMe){
      //     localStorage.setItem("local_email", email);
      //     localStorage.setItem("local_password", password);
      //   }else{
      //     localStorage.setItem("local_email", null);
      //     localStorage.setItem("local_password", null);
      //   }
      //   history.push('/admin/dashboard');
      // }else{

      //   toast.error(data.message, {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "dark",
      //     transition: Bounce,
      //     });

      // }
      if(data.otp){
        localStorage.setItem("local_varification_email", data.email);
        if(rememberMe){
              localStorage.setItem("local_email", email);
              localStorage.setItem("local_password", password);
            }else{
              localStorage.setItem("local_email", null);
              localStorage.setItem("local_password", null);
            }
            history.push('/otp');
      }else{
          toast.error(data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
          });
      }

    } catch (error) {
      console.log(error)
      toast.error("Error connecting to the server", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
        });
    }

  }

  const remember=()=>{
    setRememberMe(!rememberMe);
    localStorage.setItem("local_remember_me",!rememberMe)
  }

  useEffect(()=>{
    //User remember logic
    setEmail(localStorage.getItem("local_remember_me")=="true"? localStorage.getItem("local_email"):null);
    setPassword(localStorage.getItem("local_remember_me")=="true"? localStorage.getItem("local_password"):null);
    setRememberMe(localStorage.getItem("local_remember_me")=="true"?true:false);

    const checksession=async()=>{
      const url=process.env.REACT_APP_URL+"/check/session";
      const options={
	    method:"POST",
      credentials: "include",
	      headers:{
		      'Content-Type':'application/json'
	      },
        body:JSON.stringify({
          session_id:localStorage.getItem("local_session_token")
        })
      }

const response=await fetch(url, options);
const data=await response.json();
console.log(data)

if(!data.session){
	history.push("/sign-in")
}else{
	history.push("/admin/dashboard")
}
    }
    checksession();
  },[])

  
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto' mt={"30%"}>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>

          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='mail@gmail.com'
              mb='24px'
              fontWeight='500'
              size='lg'
              value={email}
              onChange={(e)=>{setEmail(e.target.value)}}
              required
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder='type password'
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                value={password}
                onChange={(e)=>{setPassword(e.target.value)}}
                required
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent='space-between' align='center' mb='24px'>
              <FormControl display='flex' alignItems='center'>
                <Checkbox
                  id='remember-login'
                  colorScheme='brandScheme'
                  me='10px'
                  isChecked={rememberMe}
                  onChange={remember}
                />
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  fontWeight='normal'
                  color={textColor}
                  fontSize='sm'>
                  Remember me
                </FormLabel>
              </FormControl>
              <NavLink to='/auth/forgot-password'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  w='124px'
                  fontWeight='500'>
                  Forgot password?
                </Text>
              </NavLink>
            </Flex>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              onClick={login}
              >
              Sign In
            </Button>
          </FormControl>
          <Flex
            flexDirection='column' 
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
          </Flex>
        </Flex>
      </Flex>
      <div className="modal fade" id="myModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Modal Heading</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Modal body..
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </DefaultAuth>
  );
}

export default SignIn;
