import {
  Flex,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button, 
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "@chakra-ui/react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa'; // FontAwesome copy icon
import React, { useMemo, useState } from "react";
import { SearchBar } from "../../../../components/navbar/searchBar/SearchBar";
import { MdOutlineCalendarToday } from "react-icons/md";
import { Link } from 'react-router-dom';
import ontransaction from "../../../../assets/img/notransactions.jpg"

// Custom components
import Card from "components/card/Card";

// Assets
export default function ColumnsTable(props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {coinData, filterfun}=props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [copiedRecipientWalletAddress, setCopiedRecipientWalletAddress] = useState(false);
  const [copiedRefundWalletAddress, setCopiedRefundWalletAddress] = useState(false);
  const [copiedExtraID, setCopiedExtraID] = useState(false);
  const [copiedRefundExtraID, setCopiedRefundExtraID] = useState(false);
  const [copiedTransactionID, setCopiedTransactionID] = useState(false);
  const [copiedEmail, setCopiedEmail] = useState(false);
  const [txHash, setCopiedTxHash] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [status, setStatus] = useState("All");
  const [period, setPeriod] = useState("This Day");

  const handleViewClick = (index) => {
    setSelectedTransaction(index);
    onOpen();
  };

  const handleModalClose = () => {
    setSelectedTransaction(null);
    onClose();
  };
  
  return (
    <Card
      direction='column'
      h="4500px"
      w='100%'
      px='0px'
      mt="15px"
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Coins and Tokens
        </Text>
        {/* <Menu /> */}
        <div style={{display:"flex"}}>
        <SearchBar mb={true ? { base: '10px', md: 'unset' } : 'unset'} me="10px" borderRadius="30px" filterfun={filterfun} placeholder="Ticker"/>
        <div className="dropdown">
        {/* <Button
            className="btn dropdown-toggle t-table-btn"
            id="dropdownMenuButton"
            data-toggle="dropdown" 
            aria-haspopup="true" 
            aria-expanded="false"
            bg={boxBg}
            style={{marginRight:"12px"}}
            fontSize='sm'
            fontWeight='500'
            color={textColorSecondary}
            borderRadius='7px'>
            <Icon
              as={MdOutlineCalendarToday}
              color={textColorSecondary}
              me='4px'
            />
            {period}
          </Button> */}
          {/* <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" onClick={()=>{setPeriod("This Hour"); props.apiCallForStatusAndPeriod(status, "This Hour")}}>This Hour</a>
              <a class="dropdown-item" onClick={()=>{setPeriod("This Day"); props.apiCallForStatusAndPeriod(status, "This Day")} }>This Day</a>
              <a class="dropdown-item" onClick={()=>{setPeriod("This Week"); props.apiCallForStatusAndPeriod(status, "This Week")}}>This Week</a>
              <a class="dropdown-item" onClick={()=>{setPeriod("This Month"); props.apiCallForStatusAndPeriod(status, "This Month")}}>This Month</a>
              <a class="dropdown-item" onClick={()=>{setPeriod("This Year"); props.apiCallForStatusAndPeriod(status, "This Year")}}>This Year</a>
         </div> */}
        </div>
        <div className="dropdown">
        {/* <Button
            className="btn dropdown-toggle t-table-btn"
            id="dropdownMenuButton"
            data-toggle="dropdown" 
            aria-haspopup="true" 
            aria-expanded="false"
            bg={boxBg}
            style={{marginRight:"12px"}}
            fontSize='sm'
            fontWeight='500'
            color={textColorSecondary}
            borderRadius='7px'>
            <Icon
              as={MdOutlineCalendarToday}
              color={textColorSecondary}
              me='4px'
            />
            {status}
          </Button> */}
          {/* <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" onClick={()=>{setStatus("All"); props.apiCallForStatusAndPeriod("All", period)}}>All</a>
              <a class="dropdown-item" onClick={()=>{setStatus("Successfull"); props.apiCallForStatusAndPeriod("Successfull", period)}}>Successfull</a>
              <a class="dropdown-item" onClick={()=>{setStatus("Pending"); props.apiCallForStatusAndPeriod("Pending", period)}}>Pending</a>
         </div> */}
        </div>
        <div className="dropdown">
        {/* <Button
            className="btn dropdown-toggle t-table-btn"
            id="dropdownMenuButton"
            data-toggle="dropdown" 
            aria-haspopup="true" 
            aria-expanded="false"
            bg={boxBg}
            style={{marginRight:"12px"}}
            fontSize='sm'
            fontWeight='500'
            color={textColorSecondary}
            borderRadius='7px'>
            <Icon
              as={MdOutlineCalendarToday}
              color={textColorSecondary}
              me='4px'
            />
            Support Links
          </Button> */}
          {/* <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href={props.exchangelinks.telegram} target="_blank">Telegram</a>
              <a class="dropdown-item" href={props.exchangelinks.gmail} target="_blank">Gmail</a>
              <a class="dropdown-item" href={props.exchangelinks.slack} target="_blank">Slack</a>
         </div> */}
        </div>
        </div>
      </Flex>
      <Table  variant='simple' color='gray.500' mb='24px'>
        <Thead>
            <Tr>
              
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Text textAlign={"center"}>
                  Image
                  </Text>
                </Th>

                <Th
                  pe='10px'
                  borderColor={borderColor}>
                    Ticker
                </Th>

                <Th
                  pe='10px'
                  borderColor={borderColor}>
                    Explorer
                </Th>
            </Tr>
        </Thead>
        <Tbody>
          {
            coinData.map((coin, index)=>{

              return(
                <Tr key={index}>
                {/* justifyContent="center" */}
                      <Td
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                          <Flex align='center' justifyContent="center" padding={"2px 0px"} >
                            <img className="coins-img" src={coin.logo}></img>
                        </Flex>
                      </Td>
                      <Td
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                          <Flex align='center'>
                          <Text color={textColor} fontSize='15px' fontWeight='bold'>
                            {coin.ticker}
                          </Text>
                        </Flex>
                      </Td>
                      <Td
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                          <Flex align='center'>
                          <Text color={textColor} fontSize='sm' fontWeight='400' marginRight={"0px"}>
                          <a className="coin-link" href={coin.tx_explorer} target="_blank">Link</a>
                          </Text>
                        </Flex>
                      </Td>
                </Tr>
              )
            })
          }

        </Tbody>
      </Table>
      <div style={{display:props.coinsl>0?"none":"flex", height:"100%", alignItems:"center", justifyContent:"center"}}>
      <img src={ontransaction} style={{width:"300px"}}></img>
      </div>
    </Card>
  );
}
