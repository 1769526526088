/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Button, Grid } from "@chakra-ui/react";

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import React, {useState, useEffect} from "react";
import {toast, Bounce } from 'react-toastify';


export default function Settings() {

    const [second, setSecond]=useState("");
    const [minute, setMinute]=useState("");
    const [hour, setHour]=useState("");
    const [dateOfMonth, setDateOfMonth]=useState("");
    const [month, setMonth]=useState("");
    const [dayOfWeek, setDayOfWeek]=useState("");

    const [changellySupportSlack, setChangellySupportSlack]=useState("https://google.com");
    const [changellySupportGmail, setChangellySupportGmail]=useState("https://google.com");
    const [changellySupportTelegram, setChangellySupportTelegram]=useState("https://google.com");
    const [changellyProfitPercent, setChangellyProfitPercent]=useState(0.5);

    const [changenowSupportSlack, setChangenowSupportSlack]=useState("https://google.com");
    const [changenowSupportGmail, setChangenowSupportGmail]=useState("https://google.com");
    const [changenowSupportTelegram, setChangenowSupportTelegram]=useState("https://google.com");
    const [changenowProfitPercent, setChangenowProfitPercent]=useState(0.5);

    const [changeheroSupportSlack, setChangeheroSupportSlack]=useState("https://google.com");
    const [changeheroSupportGmail, setChangeheroSupportGmail]=useState("https://google.com");
    const [changeheroSupportTelegram, setChangeheroSupportTelegram]=useState("https://google.com");
    const [changeheroProfitPercent, setChangeheroProfitPercent]=useState(0.5);

    const [exolixSupportSlack, setExolixSupportSlack]=useState("https://google.com");
    const [exolixSupportGmail, setExolixSupportGmail]=useState("https://google.com");
    const [exolixSupportTelegram, setExolixSupportTelegram]=useState("https://google.com");
    const [exolixProfitPercent, setExolixProfitPercent]=useState(0.5);

    const [godexSupportSlack, setGodexSupportSlack]=useState("https://google.com");
    const [godexSupportGmail, setGodexSupportGmail]=useState("https://google.com");
    const [godexSupportTelegram, setGodexSupportTelegram]=useState("https://google.com");
    const [godexProfitPercent, setGodexProfitPercent]=useState(0.5);

    const [stealthexSupportSlack, setStealthexSupportSlack]=useState("https://google.com");
    const [stealthexSupportGmail, setStealthexSupportGmail]=useState("https://google.com");
    const [stealthexSupportTelegram, setStealthexSupportTelegram]=useState("https://google.com");
    const [stealthexProfitPercent, setStealthexProfitPercent]=useState(0.5);

    const [letsexchangeSupportSlack, setLetsexchangeSupportSlack]=useState("https://google.com");
    const [letsexchangeSupportGmail, setLetsexchangeSupportGmail]=useState("https://google.com");
    const [letsexchangeSupportTelegram, setLetsexchangeSupportTelegram]=useState("https://google.com");
    const [letsexchangeProfitPercent, setLetsexchangeProfitPercent]=useState(0.5);

    const [simpleswapSupportSlack, setSimpleswapSupportSlack]=useState("https://google.com");
    const [simpleswapSupportGmail, setSimpleswapSupportGmail]=useState("https://google.com");
    const [simpleswapSupportTelegram, setSimpleswapSupportTelegram]=useState("https://google.com");
    const [simpleswapProfitPercent, setSimpleswapProfitPercent]=useState(0.5);

    const updateCron=async()=>{

        const url=process.env.REACT_APP_URL+"/set/cron/status";
            const options={
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({
                type:"status/removal cron", 
                second:second=="" || second==undefined?"*":second, 
                minute:minute=="" || minute==undefined?"*":minute, 
                hour:hour=="" || hour==undefined?"*":hour, 
                date_of_month:dateOfMonth=="" || dateOfMonth==undefined?"*":dateOfMonth, 
                month:month=="" || month==undefined?"*":month, 
                day_of_week:dayOfWeek=="" || dayOfWeek==undefined?"*":dayOfWeek
            })
            }
            const response=await fetch(url, options)
            const data=await response.json();
            if(data.cron){
                toast.success('Cron job successfully set', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoDismiss: true,
                    theme: "dark",
                    transition: Bounce,
                  })

            }else{
                toast.warn('Unable to set cron job', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoDismiss: true,
                    theme: "dark",
                    transition: Bounce,
                  })
            }
    }


    const getExchangesSetting=()=>{

        const changellyURL=process.env.REACT_APP_URL+"/support_links/changelly";
        const changenowURL=process.env.REACT_APP_URL+"/support_links/changenow";
        const changeheroURL=process.env.REACT_APP_URL+"/support_links/changehero";
        const exolixURL=process.env.REACT_APP_URL+"/support_links/exolix";
        const godexURL=process.env.REACT_APP_URL+"/support_links/godex";
        const stealthexURL=process.env.REACT_APP_URL+"/support_links/stealthex";
        const letsexchangeURL=process.env.REACT_APP_URL+"/support_links/letsexchange";
        const simpleswapURL=process.env.REACT_APP_URL+"/support_links/simpleswap";

        const options={
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            }
        }

        // Changelly Setting fetch
        fetch(changellyURL, options).then(response=>{
            if(!response.ok){
                toast.warn('An error occured while fetching exchange profile!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoDismiss: true,
                    theme: "dark",
                    transition: Bounce,
                  })
            }
            return response.json();

        }).then(data=>{

           setChangellySupportSlack(data.slack)
           setChangellySupportGmail(data.gmail)
           setChangellySupportTelegram(data.telegram)
           setChangellyProfitPercent(data.profit)

        }).catch(error=>{
            toast.warn('An error occured while fetching exchange profile!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoDismiss: true,
                theme: "dark",
                transition: Bounce,
              })
        })

        // Changenow Settting fetch
        fetch(changenowURL, options).then(response=>{
            if(!response.ok){
                //Response not OK
                toast.warn('An error occured while fetching exchange profile!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoDismiss: true,
                    theme: "dark",
                    transition: Bounce,
                  })
            }
            return response.json();

        }).then(data=>{
            setChangenowSupportSlack(data.slack)
            setChangenowSupportGmail(data.gmail)
            setChangenowSupportTelegram(data.telegram)
            setChangenowProfitPercent(data.profit)
        })

        // Changehero Settting fetch
        fetch(changeheroURL, options).then(response=>{
            if(!response.ok){
                //Response not OK
                toast.warn('An error occured while fetching exchange profile!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoDismiss: true,
                    theme: "dark",
                    transition: Bounce,
                  })
            }
            return response.json();

        }).then(data=>{
            setChangeheroSupportSlack(data.slack)
            setChangeheroSupportGmail(data.gmail)
            setChangeheroSupportTelegram(data.telegram)
            setChangeheroProfitPercent(data.profit)
            
        }).catch(error=>{
            toast.warn('An error occured while fetching exchange profile!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoDismiss: true,
                theme: "dark",
                transition: Bounce,
              })
        })


        // Simpleswap Settting fetch
        fetch(simpleswapURL, options).then(response=>{
            if(!response.ok){
                //Response not OK
                toast.warn('An error occured while fetching exchange profile!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoDismiss: true,
                    theme: "dark",
                    transition: Bounce,
                  })
            }
            return response.json();

        }).then(data=>{
            setSimpleswapSupportSlack(data.slack)
            setSimpleswapSupportGmail(data.gmail)
            setSimpleswapSupportTelegram(data.telegram)
            setSimpleswapProfitPercent(data.profit)

        }).catch(error=>{
            toast.warn('An error occured while fetching exchange profile!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoDismiss: true,
                theme: "dark",
                transition: Bounce,
              })
        })

        // Godex Settting fetch
        fetch(godexURL, options).then(response=>{
            if(!response.ok){
                //Response not OK
                toast.warn('An error occured while fetching exchange profile!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoDismiss: true,
                    theme: "dark",
                    transition: Bounce,
                  })
            }
            return response.json();

        }).then(data=>{
            setGodexSupportSlack(data.slack)
            setGodexSupportGmail(data.gmail)
            setGodexSupportTelegram(data.telegram)
            setGodexProfitPercent(data.profit)
        }).catch(error=>{
            toast.warn('An error occured while fetching exchange profile!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoDismiss: true,
                theme: "dark",
                transition: Bounce,
              })
        })

        // Exolix Settting fetch
        fetch(exolixURL, options).then(response=>{
            if(!response.ok){
                //Response not OK
                toast.warn('An error occured while fetching exchange profile!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoDismiss: true,
                    theme: "dark",
                    transition: Bounce,
                  })
            }
            return response.json();

        }).then(data=>{
            setExolixSupportSlack(data.slack)
            setExolixSupportGmail(data.gmail)
            setExolixSupportTelegram(data.telegram)
            setExolixProfitPercent(data.profit)
        }).catch(error=>{
            toast.warn('An error occured while fetching exchange profile!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoDismiss: true,
                theme: "dark",
                transition: Bounce,
              })
        })

        // Stealthex Settting fetch
        fetch(stealthexURL, options).then(response=>{
            if(!response.ok){
                //Response not OK
                toast.warn('An error occured while fetching exchange profile!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoDismiss: true,
                    theme: "dark",
                    transition: Bounce,
                  })
            }
            return response.json();

        }).then(data=>{
            setStealthexSupportSlack(data.slack)
            setStealthexSupportGmail(data.gmail)
            setStealthexSupportTelegram(data.telegram)
            setStealthexProfitPercent(data.profit)
        }).catch(error=>{
            toast.warn('An error occured while fetching exchange profile!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoDismiss: true,
                theme: "dark",
                transition: Bounce,
              })
        })

        // Letsexchange Settting fetch
        fetch(letsexchangeURL, options).then(response=>{
            if(!response.ok){
                //Response not OK
                toast.warn('An error occured while fetching exchange profile!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoDismiss: true,
                    theme: "dark",
                    transition: Bounce,
                  })
            }
            return response.json();

        }).then(data=>{
            setLetsexchangeSupportSlack(data.slack)
            setLetsexchangeSupportGmail(data.gmail)
            setLetsexchangeSupportTelegram(data.telegram)
            setLetsexchangeProfitPercent(data.profit)
        }).catch(error=>{
            toast.warn('An error occured while fetching exchange profile!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoDismiss: true,
                theme: "dark",
                transition: Bounce,
              })
        })
        

    }

    const updateExchangeProfileProfile=(url, exchange, slack, gmail, telegram, profit_percent)=>{

        const options={
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body:JSON.stringify({exchange:exchange, slack:slack, gmail: gmail, telegram:telegram, profit_percent:profit_percent})
        }

        // Changelly Setting fetch
        fetch(url, options).then(response=>{
            if(!response.ok){
                toast.warn('There was an error updating exchange profile!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoDismiss: true,
                    theme: "dark",
                    transition: Bounce,
                  })
            }
            return response.json();

        }).then(data=>{
            toast.success(data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoDismiss: true,
                theme: "dark",
                transition: Bounce,
              })

        }).catch(error=>{
            toast.warn('There was an error updating exchange profile!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoDismiss: true,
                theme: "dark",
                transition: Bounce,
              })
        })

    }

    useEffect(()=>{
       async function apiCall(){
            const url=process.env.REACT_APP_URL+"/get/cron/status";
            const options={
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({type:"status/removal cron"})
            }
            const response=await fetch(url, options)
            const data=await response.json();
            if(data.cron){
                setSecond(data.second);
                setMinute(data.minute);
                setMinute(data.hour);
                setDateOfMonth(data.day_of_month);
                setMonth(data.month);
                setDayOfWeek(data.day_of_week);
            }else{
                toast.warn('Unable to fetch cron job data', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoDismiss: true,
                    theme: "dark",
                    transition: Bounce,
                  })
            }
        }
        apiCall();
        getExchangesSetting();

    },[])

  return (
    <>
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <div class="accordion" id="accordionExample">
  <div class="card">
    <div class="card-header" id="headingOne">
      <h2 class="mb-0">
        <button class="exchange-prof-drop-btn btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          Cron Job
        </button>
      </h2>
    </div>

    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div class="card-body">
      <div className="container-fluid">
        <div className="row">
    <div className="col-12 bg-white cron-status-update">
    <form>
    <label for="exampleInputEmail1" className="cron-input-label">Second</label>
    <input type="number" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={second} onChange={(e)=>{setSecond(e.target.value)}}></input>
    <label for="exampleInputEmail1" className="cron-input-label">Minute</label>
    <input type="number" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Minute" value={minute} onChange={(e)=>{setMinute(e.target.value)}}></input>
    <label for="exampleInputEmail1" className="cron-input-label">Hour</label>
    <input type="number" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Minute" value={hour} onChange={(e)=>{setHour(e.target.value)}}></input>
    <label for="exampleInputEmail1" className="cron-input-label">Date of month</label>
    <input type="number" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Day of month" value={dateOfMonth} onChange={(e)=>{setDateOfMonth(e.target.value)}}></input>
    <label for="exampleInputEmail1" className="cron-input-label">Month</label>
    <input type="number" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Month" value={month} onChange={(e)=>{setMonth(e.target.value)}}></input>
    <label for="exampleInputEmail1" className="cron-input-label">Day of week</label>
    <input type="number" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Day of week" value={dayOfWeek} onChange={(e)=>{setDayOfWeek(e.target.value)}}></input>

    <div className="cron-btn"><button type="submit" class="btn btn-primary" onClick={updateCron}>Save</button></div>
    </form>
            </div>

        </div>
        </div>
      </div>
    </div>
  </div>
  {/* Changelly Profile */}
  <div class="card">
    <div class="card-header" id="headingTwo">
      <h2 class="mb-0">
        <button class="exchange-prof-drop-btn btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          Changelly Exchange Profit Percentage and Support Links
        </button>
      </h2>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
      <div class="card-body">
      <div className="container-fluid">
        <div className="row" style={{width:"90%"}}>
            <div className="col-3">
                <label>Slack</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={changellySupportSlack} onChange={(e)=>{setChangellySupportSlack(e.target.value)}}></input>
            </div>
            <div className="col-3">
                <label>Gmail</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={changellySupportGmail} onChange={(e)=>{setChangellySupportGmail(e.target.value)}}></input>
            </div>
            <div className="col-3">
                <label>Telegram</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={changellySupportTelegram} onChange={(e)=>{setChangellySupportTelegram(e.target.value)}}></input>
            </div>
            <div className="col-2">
                <label>Profit Percent</label>
                <input type="number" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={changellyProfitPercent} onChange={(e)=>{setChangellyProfitPercent(e.target.value)}}></input>
            </div>
            <div className="col-1">
            <Button onClick={()=>{updateExchangeProfileProfile(process.env.REACT_APP_URL+"/changelly_profile/update", "changelly", changellySupportSlack, changellySupportGmail, changellySupportTelegram, changellyProfitPercent)}} style={{color:"#ffffff", background:"#0f89f5", width:"100px", borderRadius:"6px", position:"absolute", bottom:"10px"}}>Update</Button>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  {/* Changenow Profile */}
  <div class="card">
    <div class="card-header" id="headingTwo">
      <h2 class="mb-0">
        <button class="exchange-prof-drop-btn btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          Changenow Exchange Profit Percentage and Support Links
        </button>
      </h2>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
      <div class="card-body">
      <div className="container-fluid">
        <div className="row" style={{width:"90%"}}>
            <div className="col-3">
                <label>Slack</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={changenowSupportSlack} onChange={(e)=>{setChangenowSupportSlack(e.target.value)}}></input>
            </div>
            <div className="col-3">
                <label>Gmail</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={changenowSupportGmail} onChange={(e)=>{setChangenowSupportGmail(e.target.value)}}></input>
            </div>
            <div className="col-3">
                <label>Telegram</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={changenowSupportTelegram} onChange={(e)=>{setChangenowSupportTelegram(e.target.value)}}></input>
            </div>
            <div className="col-2">
                <label>Profit Percent</label>
                <input type="number" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={changenowProfitPercent} onChange={(e)=>{setChangenowProfitPercent(e.target.value)}}></input>
            </div>
            <div className="col-1">
            <Button onClick={()=>{updateExchangeProfileProfile(process.env.REACT_APP_URL+"/changenow_profile/update", "changenow", changenowSupportSlack, changenowSupportGmail, changenowSupportTelegram, changenowProfitPercent)}} style={{color:"#ffffff", background:"#0f89f5", width:"100px", borderRadius:"6px", position:"absolute", bottom:"10px"}}>Update</Button>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  {/* Changehero */}
  <div class="card">
    <div class="card-header" id="headingTwo">
      <h2 class="mb-0">
        <button class="exchange-prof-drop-btn btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
          Changehero Exchange Profit Percentage and Support Links
        </button>
      </h2>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
      <div class="card-body">
      <div className="container-fluid">
        <div className="row" style={{width:"90%"}}>
            <div className="col-3">
                <label>Slack</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={changeheroSupportSlack} onChange={(e)=>{setChangeheroSupportSlack(e.target.value)}}></input>
            </div>
            <div className="col-3">
                <label>Gmail</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={changeheroSupportGmail} onChange={(e)=>{setChangeheroSupportGmail(e.target.value)}}></input>
            </div>
            <div className="col-3">
                <label>Telegram</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={changeheroSupportTelegram} onChange={(e)=>{setChangeheroSupportTelegram(e.target.value)}}></input>
            </div>
            <div className="col-2">
                <label>Profit Percent</label>
                <input type="number" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={changeheroProfitPercent} onChange={(e)=>{setChangeheroProfitPercent(e.target.value)}}></input>
            </div>
            <div className="col-1">
            <Button onClick={()=>{updateExchangeProfileProfile(process.env.REACT_APP_URL+"/changehero_profile/update", "changehero", changeheroSupportSlack, changeheroSupportGmail, changeheroSupportTelegram, changeheroProfitPercent)}} style={{color:"#ffffff", background:"#0f89f5", width:"100px", borderRadius:"6px", position:"absolute", bottom:"10px"}}>Update</Button>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
    {/* Exolix */}
    <div class="card">
    <div class="card-header" id="headingTwo">
      <h2 class="mb-0">
        <button class="exchange-prof-drop-btn btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
          Exolix Exchange Profit Percentage and Support Links
        </button>
      </h2>
    </div>
    <div id="collapseFive" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
      <div class="card-body">
      <div className="container-fluid">
        <div className="row" style={{width:"90%"}}>
            <div className="col-3">
                <label>Slack</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={exolixSupportSlack} onChange={(e)=>{setExolixSupportSlack(e.target.value)}}></input>
            </div>
            <div className="col-3">
                <label>Gmail</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={exolixSupportGmail} onChange={(e)=>{setExolixSupportGmail(e.target.value)}}></input>
            </div>
            <div className="col-3">
                <label>Telegram</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={exolixSupportTelegram} onChange={(e)=>{setExolixSupportTelegram(e.target.value)}}></input>
            </div>
            <div className="col-2">
                <label>Profit Percent</label>
                <input type="number" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={exolixProfitPercent} onChange={(e)=>{setExolixProfitPercent(e.target.value)}}></input>
            </div>
            <div className="col-1">
            <Button onClick={()=>{updateExchangeProfileProfile(process.env.REACT_APP_URL+"/exolix_profile/update", "exolix", exolixSupportSlack, exolixSupportGmail, exolixSupportTelegram, exolixProfitPercent)}} style={{color:"#ffffff", background:"#0f89f5", width:"100px", borderRadius:"6px", position:"absolute", bottom:"10px"}}>Update</Button>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
      {/* Godex */}
      <div class="card">
    <div class="card-header" id="headingTwo">
      <h2 class="mb-0">
        <button class="exchange-prof-drop-btn btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
          Godex Exchange Profit Percentage and Support Links
        </button>
      </h2>
    </div>
    <div id="collapseSix" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
      <div class="card-body">
      <div className="container-fluid">
        <div className="row" style={{width:"90%"}}>
            <div className="col-3">
                <label>Slack</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={godexSupportSlack} onChange={(e)=>{setGodexSupportSlack(e.target.value)}}></input>
            </div>
            <div className="col-3">
                <label>Gmail</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={godexSupportGmail} onChange={(e)=>{setGodexSupportGmail(e.target.value)}}></input>
            </div>
            <div className="col-3">
                <label>Telegram</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={godexSupportTelegram} onChange={(e)=>{setGodexSupportTelegram(e.target.value)}}></input>
            </div>
            <div className="col-2">
                <label>Profit Percent</label>
                <input type="number" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={godexProfitPercent} onChange={(e)=>{setGodexProfitPercent(e.target.value)}}></input>
            </div>
            <div className="col-1">
            <Button onClick={()=>{updateExchangeProfileProfile(process.env.REACT_APP_URL+"/godex_profile/update", "godex", godexSupportSlack, godexSupportGmail, godexSupportTelegram, godexProfitPercent)}} style={{color:"#ffffff", background:"#0f89f5", width:"100px", borderRadius:"6px", position:"absolute", bottom:"10px"}}>Update</Button>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
      {/* Stealthex */}
      <div class="card">
    <div class="card-header" id="headingTwo">
      <h2 class="mb-0">
        <button class="exchange-prof-drop-btn btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
          Stealthex Exchange Profit Percentage and Support Links
        </button>
      </h2>
    </div>
    <div id="collapseSeven" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
      <div class="card-body">
      <div className="container-fluid">
        <div className="row" style={{width:"90%"}}>
            <div className="col-3">
                <label>Slack</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={stealthexSupportSlack} onChange={(e)=>{setStealthexSupportSlack(e.target.value)}}></input>
            </div>
            <div className="col-3">
                <label>Gmail</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={stealthexSupportGmail} onChange={(e)=>{setStealthexSupportGmail(e.target.value)}}></input>
            </div>
            <div className="col-3">
                <label>Telegram</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={stealthexSupportTelegram} onChange={(e)=>{setStealthexSupportTelegram(e.target.value)}}></input>
            </div>
            <div className="col-2">
                <label>Profit Percent</label>
                <input type="number" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={stealthexProfitPercent} onChange={(e)=>{setStealthexProfitPercent(e.target.value)}}></input>
            </div>
            <div className="col-1">
            <Button onClick={()=>{updateExchangeProfileProfile(process.env.REACT_APP_URL+"/stealthex_profile/update", "stealthex", stealthexSupportSlack, stealthexSupportGmail, stealthexSupportTelegram, stealthexProfitPercent)}} style={{color:"#ffffff", background:"#0f89f5", width:"100px", borderRadius:"6px", position:"absolute", bottom:"10px"}}>Update</Button>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
        {/* Letsexchange */}
        <div class="card">
    <div class="card-header" id="headingTwo">
      <h2 class="mb-0">
        <button class="exchange-prof-drop-btn btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
        Letsexchange Exchange Profit Percentage and Support Links
        </button>
      </h2>
    </div>
    <div id="collapseEight" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
      <div class="card-body">
      <div className="container-fluid">
        <div className="row" style={{width:"90%"}}>
            <div className="col-3">
                <label>Slack</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={letsexchangeSupportSlack} onChange={(e)=>{setLetsexchangeSupportSlack(e.target.value)}}></input>
            </div>
            <div className="col-3">
                <label>Gmail</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={letsexchangeSupportGmail} onChange={(e)=>{setLetsexchangeSupportGmail(e.target.value)}}></input>
            </div>
            <div className="col-3">
                <label>Telegram</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={letsexchangeSupportTelegram} onChange={(e)=>{setLetsexchangeSupportTelegram(e.target.value)}}></input>
            </div>
            <div className="col-2">
                <label>Profit Percent</label>
                <input type="number" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={letsexchangeProfitPercent} onChange={(e)=>{setLetsexchangeProfitPercent(e.target.value)}}></input>
            </div>
            <div className="col-1">
            <Button onClick={()=>{updateExchangeProfileProfile(process.env.REACT_APP_URL+"/letsexchange_profile/update", "letsexchange",letsexchangeSupportSlack, letsexchangeSupportGmail, letsexchangeSupportTelegram, letsexchangeProfitPercent)}} style={{color:"#ffffff", background:"#0f89f5", width:"100px", borderRadius:"6px", position:"absolute", bottom:"10px"}}>Update</Button>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>

        {/* Simpleswap */}
          <div class="card">
    <div class="card-header" id="headingTwo">
      <h2 class="mb-0">
        <button class="exchange-prof-drop-btn btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
        Simpleswap Exchange Profit Percentage and Support Links
        </button>
      </h2>
    </div>
    <div id="collapseNine" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
      <div class="card-body">
      <div className="container-fluid">
        <div className="row" style={{width:"90%"}}>
            <div className="col-3">
                <label>Slack</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={simpleswapSupportSlack} onChange={(e)=>{setSimpleswapSupportSlack(e.target.value)}}></input>
            </div>
            <div className="col-3">
                <label>Gmail</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={simpleswapSupportGmail} onChange={(e)=>{setSimpleswapSupportGmail(e.target.value)}}></input>
            </div>
            <div className="col-3">
                <label>Telegram</label>
                <input type="txt" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={simpleswapSupportTelegram} onChange={(e)=>{setSimpleswapSupportTelegram(e.target.value)}}></input>
            </div>
            <div className="col-2">
                <label>Profit Percent</label>
                <input type="number" class="form-control cron-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Second" value={simpleswapProfitPercent} onChange={(e)=>{setSimpleswapProfitPercent(e.target.value)}}></input>
            </div>
            <div className="col-1">
            <Button onClick={()=>{updateExchangeProfileProfile(process.env.REACT_APP_URL+"/simpleswap_profile/update", "simpleswap",simpleswapSupportSlack, simpleswapSupportGmail, simpleswapSupportTelegram, simpleswapProfitPercent)}} style={{color:"#ffffff", background:"#0f89f5", width:"100px", borderRadius:"6px", position:"absolute", bottom:"10px"}}>Update</Button>
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
    </Box>
    </>
  );
}
