/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, SimpleGrid, Flex } from "@chakra-ui/react";
import CoinsTable from "views/admin/dataTables/components/CoinsTable";

import React, { useEffect, useState } from "react";

export default function Settings() {

  const [coinsdata, setCoinsData]=useState([]);
  const [filteredCoins, setFilteredCoins]=useState([]);
  const [presentCoins, setPresentCoins]=useState([]);
  const [currentPage, setCurrentPage]=useState(1);
  const [transactionsPerPage, setTransactionsPerPage]=useState(50);
  const [exchangelinks, setExchangeLinks]=useState({});
  const [coinsl, setCoinsL] =useState(0);

  async function apiCall(){
    console.log("Xxxxxxx")
    const options={
      method:"GET",
      headers:{
        "Content-Type": "application/json"
      }
    }
    //Transaction Response
    const response=await fetch (process.env.REACT_APP_URL+"/coins-tokens", options); 
    const data=await response.json();
    console.log(data)
    setCoinsL(data.coins.length);
    setPresentCoins(data.coins);
    setCoinsData(data.coins);
  }

  // Chakra Color Mode
  useEffect(()=>{
    apiCall()
    }, [])

    const indexOfLastTransaction = currentPage * transactionsPerPage;
    const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
    const currentCoins =  coinsdata.slice(indexOfFirstTransaction, indexOfLastTransaction);

    const pageNumbers=[];
    for (let i = 1; i<= Math.ceil( coinsdata.length / transactionsPerPage); i++ ){
      pageNumbers.push(i);
    }

    function paginate(e, number){
      e.preventDefault();
      setCurrentPage(number);
    }

    function nextPage(){
      if(currentPage<pageNumbers.length){
        setCurrentPage(currentPage+1);
      }
    }

    function prevPage(){
      if(currentPage>1){
        setCurrentPage(currentPage-1);
      }
    }

    const filterCoins = (filterValue) => {

      const searchTerm = filterValue.toLowerCase();
  
      const filteredCoins = presentCoins.filter((transaction) => {
        const { ticker } = transaction;
        return (
          (ticker && ticker.toLowerCase().includes(searchTerm))
        );
      });
  
      const exactMatch = [];
      const partialMatch = [];
  
      filteredCoins.forEach((transaction) => {
        const { ticker } = transaction;
        if (
          (ticker && ticker.toLowerCase() === searchTerm)
        ) {
          exactMatch.push(transaction);
        } else {
          partialMatch.push(transaction);
        }
      });
      
      const rearrangedTransactions = exactMatch.concat(partialMatch);

      setFilteredCoins(rearrangedTransactions);
      setCoinsData(rearrangedTransactions)
    
    };
    

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1}}
        spacing={{ base: "20px", xl: "20px" }}>
        <CoinsTable
          coinData={currentCoins}
          filterfun={filterCoins}
          coinsl={coinsl}
        />
        <Flex display={coinsl>0?"flex":"none"} justifyContent={"center"}>
        <button onClick={prevPage} type="button" class="btn btn-primary" style={{marginRight:"10px", fontWeight:"bold"}} >{"<"}</button>
        <nav className="pagination">
          {pageNumbers.map((number)=>
            {
              if(currentPage==number  || pageNumbers.length==number){
                return(
                  <li key={number} className="page-item">
                    <a onClick={(e)=>{paginate(e, number)}} href="!#" className="page-link" style={{background:currentPage==number?"#2295ee":"white", color:currentPage==number?"white":"blue", fontWeight:"bold"}}>{number}</a>      
                  </li>
                  )
              }
              }
          )}
        </nav>
        <button onClick={nextPage} type="button" class="btn btn-primary" style={{marginLeft:"10px", fontWeight:"bold"}}>{">"}</button>
        </Flex>
      </SimpleGrid>
    </Box>
  );
}
